<template>
    <transition name="slide">
        <div class="overlay" v-show="show">
            <div class="mask" @click.stop="overlayClick()" ref="mask">

            </div>
            <div :class="{content: !down, contentdown: down}">
                <slot></slot>
                <div class="down" v-if="down">
                    <div class="title">
                        <div class="close" @click="closeAll()">×</div>
                        {{title}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <slot name="downContent"></slot>
                    <app-button
                        bar bigWord type="primary"
                        :forbid="processing"
                        @click="onClick"
                        v-if="downBtn"
                    >{{downBtn}}</app-button>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    .slide-enter-active {
        animation: slide-in .3s ease-out forwards;
    }

    .slide-leave-active {
        animation: slide-out .3s ease-in forwards;
    }

    @keyframes slide-in {
        from {
            transform: translateY(-20px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes slide-out {
        from {
            transform: translateY(0);
            opacity: 1;
        }
        to {
            transform: translateY(-20px);
            opacity: 0;
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1024;

        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.73);
        }

        .content {
            position: absolute;
            margin: 0 auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .contentdown {
            width: 100%;
            position: absolute;
            margin: 0 auto;
            bottom: 0;
            background-color: #ffffff;
        }

        .down {
            width: 100%;

            .title {
                width: 100%;
                height: 50px;
                text-align: center;
                color: #383838;
                font-size: 16px;
                font-weight: bold;
                position: relative;
                line-height: 50px;
                border-bottom: 1px solid #bbb;
                .close {
                    position: relative;
                    width: 50px;
                    font-size: 30px;
                    font-weight: normal;
                    color: #bbb;
                    float: left;
                }
            }
        }
    }
</style>

<script>
export default {
  name: 'app-overlay',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      default: _ => false
    },
    noOpacity: {
      type: Boolean,
      default: false
    },
    down: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    downBtn: {
      type: String,
      default: ''
    },
    processing: {
      type: Boolean,
      default: false
    },
    dosome: {
      type: Function,
      default: _ => false
    }
  },
  data () {
    return {
      // processing: false
    }
  },
  mounted () {
    if (this.noOpacity) {
      this.$refs.mask.style.backgroundColor = '#333'
    }
  },
  methods: {
    overlayClick () {
      this.closeAll()
      return this.onClose ? this.onClose() : false
    },
    closeAll () {
      this.$emit('update:show', false)
    },
    onClick () {
      this.$emit('update:processing', false)
      this.dosome()
    }
  }
}
</script>
