export const GETTERS = {
  TOKEN: 'token'
}

export const MUTATIONS = {
  SETTOKEN: 'setToken',
  CLEARTOKEN: 'clearToken'
}

export const ACTIONS = {
}

export const WEBTOKEN = 'user-token'
