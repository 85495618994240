import Vue from 'vue'
import Router from 'vue-router'
import { routes } from './routes'

Vue.use(Router)

const router = new Router({
  routes,
  mode: 'history',
  base: ''
})

router.beforeEach((t, f, n) => {
  t.meta.title && (document.title = t.meta.title)
  n()
})

export default router
