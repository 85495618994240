export default {
  methods: {
    testfunc () {
      console.log('click ok')
    },
    toHome () {
      this.$router.push({ name: 'Home' })
    },
    inWechat: f => navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
  },
  computed: {
    isIOS () {
      const agent = navigator.userAgent.toLowerCase()
      return agent.indexOf('iphone') !== -1 || agent.indexOf('ipad') !== -1
    },
    isPhone () {
      const agent = navigator.userAgent.toLowerCase()
      const arr = [
        'android', 'symbianos', 'midp', 'ucweb',
        'windows ce', 'windows phone',
        'windows mobile', 'rv:1.2.3.4'
      ]
      for (const i in arr) {
        const k = arr[i]
        if (agent.indexOf(k) > 0) {
          return true
        }
      }
    }
  }
}
