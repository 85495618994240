import { GETTERS, MUTATIONS, WEBTOKEN } from '../types'

export default {
  state: {
    token: ''
  },
  getters: {
    [GETTERS.TOKEN]: state => state.token
  },
  mutations: {
    [MUTATIONS.SETTOKEN]: (state, payload) => {
      state.token = payload
      localStorage.setItem(WEBTOKEN, payload)
    },
    [MUTATIONS.CLEARTOKEN]: state => {
      state.token = null
      localStorage.removeItem(WEBTOKEN)
    }
  }
}
