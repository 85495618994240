
const routes = [
  {
    path: '',
    name: 'Home',
    component: () => import('@/views/game/fish999'),
    meta: {}
  },
  {
    path: '/pay-success',
    redirect: 'pay/success'
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('@/views/pay/index'),
    children: [
      {
        path: 'success',
        name: 'PaySuccess',
        component: () => import('@/views/pay/success')
      },
      {
        path: 'guide',
        name: 'PayGuide',
        component: () => import('@/views/pay/guide')
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
    // name: 'Page404',
    // component: () => import('@/views/error/404')
  }
]

export {
  routes
}
