// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueAxios from 'vue-axios'
// import VueCarousel from 'vue-carousel'

import App from './App.vue'
import axios from './config/axios'

import mixin from './mixin'
import router from './router'
import store from './store'

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import 'swiper/css/swiper.css'

import Overlay from './components/overlay.vue'

require('./utils/hotcss')

Vue.use(VueAxios, axios)
// Vue.use(VueCarousel)
Vue.use(VueAwesomeSwiper)

Vue.mixin(mixin)

Vue.component(Overlay.name, Overlay)

Vue.config.productionTip = false
// Vue.config.errorHandler = function (err, vm, info) {
//     let json = JSON.stringify(err);
//     let params = {err: json, vm: err.stack, info: err.message};
//     axios.post('catch/error', params).then(res => {
//         console.log(res);
//     }).catch(err => {
//         console.log(err);
//     });
// };

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
