import axios from 'axios'
import store from '../store/index'
import { GETTERS, MUTATIONS, WEBTOKEN } from '../store/types'

axios.defaults.timeout = 10000

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem(WEBTOKEN) || store.getters[GETTERS.TOKEN]
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      store.commit(MUTATIONS.SETTOKEN, response.headers.authorization)
    }
    return response.data
  },
  error => {
    for (const x in error) {
      console.log('http-error', x, error[x])
    }

    if (error.response) {
      let str
      let arr
      switch (error.response.status) {
        case 401:
          store.commit(MUTATIONS.CLEARTOKEN)
          str = location.search
          str = str.replace('?', '')
          arr = str.split('&')
          arr = arr.filter((val) => {
            const str = val.split('=')[0]
            return str !== 'code' && str !== 'state'
          })
          location.href = location.origin + location.pathname + '?' + arr.join('&')
          break
      }
      return Promise.reject(error.response)
    }

    if (error.code && error.code === 'ECONNABORTED') {
      return Promise.reject(new Error('net error!'))
    }

    return Promise.reject(new Error('error!'))
  }
)

export default axios
