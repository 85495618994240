<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  methods: {
    exceptionCatch () {
      window.onerror = (message, source, lineno, colno, error) => {
        const obj = {
          message,
          source,
          lineno,
          colno
        }
        const json = JSON.stringify(obj)
        this.$http.post('catch/error', { err: json, vm: error.stack, info: error.message }).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
        })
      }
    }
  },
  created () {
    this.exceptionCatch()
  },
  errorCaptured (err, vm, info) {
    const json = JSON.stringify(err)
    const params = { err: json, vm: err.stack, info: err.message }
    this.$http.post('catch/error', params).then(res => {
      console.log(res)
    }).catch(err => {
      console.log(err)
    })
    return false
  }
}
</script>

<style lang="scss">
@import "style/mobile.css";
@import "style/utils";
body {
  background-color: rgb(29, 27, 51);
}
#app {
  // max-width: px2rem(540);
  max-width: 100vw;
  margin: 0 auto;
}
</style>
